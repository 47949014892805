import React from "react"
import Layout from "../components/Layout"

const submitted = () => {
  return (
    <Layout>
      <section className="contact-page">
        <article className="contact-form">
          <h3><i>THANKS FOR CONTACTING ME</i></h3>
          <h5>I WILL GET BACK TO YOU SOON</h5>
          
        </article>
      </section>
    </Layout>
  )
}

export default submitted
